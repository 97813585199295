<template>
  <div>
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :to="{ name: 'products-create' }"
              class="ml-2"
              variant="primary"
          >
            Létrehozás
          </b-button>
        </div>
      </b-form-group>
    </div>
    <!-- input search -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Keresés</label>
          <b-form-input
              v-model="searchTerm"
              placeholder="Keresés"
              type="text"
              class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <b-overlay :show="loading">
      <vue-good-table
          :columns="columns"
          :rows="allProducts"
          :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
          :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'id'">
            <div class="text-center">
              {{ props.row.id }}
            </div>
          </span>

          <span v-if="props.column.field === 'active'">
            <div v-if="props.row.active === 1" class="text-center">
              <b-badge variant="success">Aktív</b-badge>
            </div>
            <div v-else class="text-center">
              <b-badge variant="danger">Inaktív</b-badge>
            </div>
          </span>
          <span v-if="props.column.field === 'article_number'">
            <div class="text-center">
              {{ props.row.article_number }}
            </div>
          </span>
          <span v-if="props.column.field === 'name'">
            <div class="text-center">
              {{ props.row.name }}
            </div>
          </span>

          <span v-if="props.column.field === 'quantity'">
            <div
                v-for="(item, index) in props.row.quantity"
                :key="index"
                style="font-size: small; font-weight: bold; text-align: center"
            >
              {{ item.in }}: {{ item.value }} {{ item.unit }}
            </div>
          </span>
          <span v-if="props.column.field === 'unit'">
            <div class="text-center">
              {{ props.row.unit }}
            </div>
          </span>

          <span v-else-if="props.column.field === 'gross_sale_price'">
              <div
                  v-for="(item, index) in props.row.pricesByWarehouses"
                  :key="index"
                  style="font-size: small;  text-align: center"
              >
              <span style="font-weight: bold;">{{ item.warehouse_name }}</span>: {{ item.price }} Ft
                <span v-if="item.override">
                     <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                          stroke="currentColor" class="size-2" style="color:red; width: 20px">
                      <path stroke-linecap="round" stroke-linejoin="round"
                            d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"/>
                    </svg>
                </span>
            </div>
          </span>

          <span v-if="props.column.field === 'profit'">
            <div class="text-center">
              {{ props.row.profit }}
            </div>
          </span>

          <span v-else-if="props.column.field === 'unit_price'">
            <div>{{ props.row.unit_price }} Ft</div>
          </span>
          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                    :to="{ name: 'products-edit', params: { id: props.row.id } }"
                >
                  <feather-icon icon="Edit2Icon" class="mr-50"/>
                  <span>Szerkesztés</span>
                </b-dropdown-item>
                <b-dropdown-item
                    @click.stop="setVisibility(props.row.id)"
                >
                  <feather-icon icon="PowerIcon" class="mr-50"/>
                  <span>Aktív állapot váltás</span>
                </b-dropdown-item>
                <b-dropdown-item
                    @click.stop="deleteProductConfirm(props.row.id)"
                >
                  <feather-icon icon="TrashIcon" class="mr-50"/>
                  <span>Törlés</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Jelenleg </span>
              <b-form-select
                  v-model="pageLength"
                  :options="['3', '5', '10']"
                  class="mx-1"
                  @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap">
                találat a(z) {{ props.total }} -ból / ből
              </span>
            </div>
            <div>
              <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18"/>
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18"/>
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-overlay>
  </div>
</template>

<script>
import {
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BBadge,
  BDropdownItem,
  BButton,
  BOverlay,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
import {VueGoodTable} from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import {mapGetters, mapActions} from "vuex";

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BBadge,
    BDropdownItem,
    BButton,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 10,
      columns: [
        {
          label: "Id",
          field: "id",
          type: "number",
          filterOptions: {
            enabled: true,
            placeholder: "Id keresés",
          },
        },
        {
          label: "Aktív",
          field: "active",
          type: "bool",
          filterOptions: {
            enabled: true,
            placeholder: "Id keresés",
          },
        },
        {
          label: "Cikkszám",
          field: "article_number",
          filterOptions: {
            enabled: true,
            placeholder: "Cikkszám keresés",
          },
        },
        {
          label: "Name",
          field: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Név keresés",
          },
        },
        {
          label: "Raktárkészlet",
          field: "quantity",
          filterOptions: {
            enabled: true,
            placeholder: "Raktárkészlet keresés",
          },
        },
        {
          label: "Mennyiség egység",
          field: "unit",
          filterOptions: {
            enabled: true,
            placeholder: "Mennyiség egység keresés",
          },
        },
        {
          label: "Beszerzési egységár (nettó)",
          field: "unit_price",
          type: "number",
          filterOptions: {
            enabled: true,
            placeholder: "Beszerzési egységár keresés",
          },
        },
        {
          label: "Profit",
          field: "profit",
          type: "number",
          filterOptions: {
            enabled: true,
            placeholder: "Profit keresés",
          },
        },
        {
          label: "Eladási ár",
          field: "gross_sale_price",
          type: "number",
          filterOptions: {
            enabled: true,
            placeholder: "Eladási ár keresés",
          },
        },
        // {
        //   label: 'Eladási ár (bruttó)',
        //   field: 'gross_sale_price',
        //   type: 'number',
        //   // filterOptions: {
        //   //   enabled: true,
        //   //   placeholder: 'Eladási ár keresés',
        //   // },
        // },
        {
          label: "Műveletek",
          field: "action",
        },
      ],
      loading: false,
      searchTerm: "",
    };
  },
  methods: {
    ...mapActions(["fetchProducts", "deleteProduct", "toggleVisibility"]),
    initProducts() {
      this.loading = true;
      this.fetchProducts().then(() => {
        this.loading = false;
      });
    },
    deleteProductConfirm(id) {
      this.$swal({
        title: "Biztos vagy benne?",
        text: "A rekord törlése nem visszavonható!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Törlés",
        cancelButtonText: "Mégse",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteProduct(id);
          this.$swal({
            icon: "success",
            title: "Sikeres törlés!",
            text: "A rekord törlése megtörtént",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
    async setVisibility(id) {
      await this.toggleVisibility(id);
      this.initProducts();
    },
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
    ...mapGetters(["allProducts"]),
  },
  created() {
    this.initProducts();
  },
};
</script>
